.createItem{
    margin-bottom: 20px;
    clear: both;
    overflow: hidden;
}
.createItemTitle{
    margin-bottom: 10px;
    font-weight: bold;
}
.createItem .createItemInput{
    width: 400px;
    border-radius: 5px;
}
.createItemTextarea{
    width: 400px;
    padding:10px;
    height: 80px;
    border-radius: 5px;
}
.editorWrapper{
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #fff;
}
.insertNFT{
    color: #1890ff!important;
}
.searchNFTWrapper{
    width: 300px;
    margin: 10px auto;
}
.insertHtmlWrapper{
    width: 240px;
    margin: 40px auto 20px;
    height: 200px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    cursor: pointer;
}
.checkedNFTShow{
    width: 240px;
    height: 200px;
    overflow: hidden;
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    left: 0;
    top: 0;
}
.checkedNFTIcon{
    position: absolute;
    font-size: 30px;
    left: 50%;
    top:45%;
}
.previewTopicWrapper img{
    max-width: 100%;
}