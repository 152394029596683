.uploadLabel{
    float: left;
}
.uploadLabel:after{
    clear: both;
}
.uploadBtn{
    color: #999;
    border: 2px dashed #999;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content:center;
    align-items: center;
    cursor: pointer;
}

.uploadPreview{
    display: block;
    width: 100%;
    position: absolute;
    z-index: 3;
}
.uploadIcon{
    font-size: 2.3rem;
}