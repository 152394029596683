.profileImage{
    width: 40px;
    border-radius: 20px;
}
.walletAddress{
    display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    text-align: left;
    vertical-align: middle;
}
.beCertifyIcon{
    color: #999;
    margin-right: 5px;
}
.hadCertifyIcon{
    color: #52c41a;
    margin-right: 5px;
}
.searchWrapper{
    width: 400px;
    margin: 10px auto 60px;
}
.userList .pageWrapper{
    margin-top: 20px;
    text-align: center;
}