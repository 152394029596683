.logo{
    height: 84px;
}
.logoImg{
    width: 42px;
    margin: 15px 0 0 15px ;
}
.logout{
    position: relative;
    color: rgb(255, 255, 255);
    float: right;
    cursor: pointer;
    z-index: 2;
    padding: 0 20px;
}